@import '../../styles/index.scss';

.about {
  &.section {
    padding: 50px;
    @include respond('ipad-pro') {
      padding: 0px 50px;
    }
    @include respond('ipad') {
      padding: 0px 30px;
    }
    @include respond('phone') {
      padding: 0px 20px;
    }
  }
  &-container {
    display: flex;
    @include respond('ipad') {
      flex-direction: column;
    }
  }
  &-content {
    width: 50%;
    align-self: center;
    margin: 70px 0;
    padding: 0 55px 0 0;
    @include respond('ipad-pro') {
      width: 55%;
    }
    @include respond('ipad') {
      width: 100%;
      padding: 0 0 30px 0;
    }
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    @include theme() {
      color: t($title);
    }
    @include respond('ipad') {
      font-size: 16px;
    }
  }
  &-imgs {
    position: relative;
    width: 45%;
    @include respond('ipad') {
      width: 100%;
    }
  }
  &-img1, &-img2 {
    height: 350px;
    position: absolute;
    object-fit: cover;
    width: 280px;
    @include respond('ipad-pro') {
      width: 200px;
    }
    @include respond('ipad') {
      position: relative;
      width: 100%;
    }
  }
  &-img1 {
    bottom: 30px;
    right: 30px;
    z-index: 1;
    @include respond('ipad') {
      top: auto;
      left: auto;
      right: auto;
      width: 100%;
      height: 300px;
      z-index: 0;
    }
  }
  &-img2 {
    top: 50px;
    left: 30px;
    @include respond('ipad') {
      display: none;
    }
  }
}