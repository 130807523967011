@import './fonts.scss';
@import './themes.scss';
@import './mixins.scss';


.section {
  @include respond('ipad-pro') {
    padding: 0px 50px;
  }
  @include respond('ipad') {
    padding: 0px 30px;
  }
  @include respond('phone') {
    padding: 0px 20px;
  }
}

.loading {
  z-index: 2;
  position: absolute;
  top: 0;
  background-color: black;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.container {
  margin-left: 100px;
  @include respond('phone') {
    padding: 0;
    margin-left: 60px;
    overflow-x: hidden;
  }
}

* {
  box-sizing: border-box !important;
  text-align: left;
  cursor: auto;
  scroll-behavior: smooth;
  transition: all 2s ease;
}

body {
  margin: 0;
  font-family: $roboto;
  transition: all 1.5s ease;
  @include theme() {
    background: t($bg);
  }
  overflow-x: hidden;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: black;
  margin-right: 5px;
}

.hidden {
  display: none;
}

.oposite {
  text-shadow: 2px 4px 3px rgba(0,0,0,0.5);
  transition: all 1.5s ease;
  @include theme() {
    color: t($bg);
  }
  @include respond('ipad') {
      color: white !important;
    }
}

.bold {
  font-weight: 700;
}
.dark {
  font-weight: 700;
  @include theme() {
    color: t($title);
  }
}

.title {
  font-family: $cairo;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 120px;
  margin: 0;
  @include theme() {
    color: t($title);
  }
  transition: all 1.5s ease;
}

.text {
  font-family: $cairo;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120px;
  margin: 0;
  text-align: left;
  @include theme() {
    color: t($title);
  }
  transition: all 0.5s ease;
}

.alert {
  font-family: $cairo;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120px;
  margin: 0;
  text-align: left;
  color: red;
  transition: all 0.5s ease;
}

.section {
  min-height: 100%;
  position: relative;
  @include respond('large') {
    width: 1200px;
    margin: auto;
  }
  @include respond('ipad') {
    width: 100%;
  }
  @include respond('ipad-pro') {
    margin-top: 20px;
  }
}

.cursor {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.spotify-green {
  color: #1BD760;
}

.cta-btn {
  padding: 20px 30px;
  margin-top: 10px;
  background-color: white;
  color: black;
  border: 1px solid #000;
  font-weight: 700;
  border-radius: 10px;
  &:hover {
    background-color: black;
    color: white;
    border: 1px solid white;
    cursor: pointer;
  }
}

.cta-btn--container {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  cursor: pointer;
}