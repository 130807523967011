@import '../../styles/index.scss';

.estudio {
  &.section {
    padding: 50px;
    @include respond('ipad-pro') {
      padding: 0px 50px;
    }
    @include respond('ipad') {
      padding: 0px 30px;
    }
    @include respond('phone') {
      padding: 0px 20px;
    }
  }
  &-container {
    display: flex;
    @include respond('ipad-pro') {
      flex-direction: column;
    }
  }
  &-content {
    position: relative;
    padding: 75px 55px 55px 0;
    @include respond('ipad') {
      padding: 0 0 30px 0;
    }
  }
  &-logo {
    position: absolute;
    top: 64px;
    right: 30px;
    @include respond('phone') {
      display: none;
    }
  }
  &-location {
    display:flex;
    align-items: center;
    margin-bottom: 38px;
    svg {
      margin-right: 10px;
    }
    &--text {
      line-height: 12px;
      font-size: 12px;
      font-weight: 600;
      @include theme() {
        color: t($text);
      }
    }
  }
  &-text {
    font-weight: 400;    
    font-size: 16px;
    line-height: 21px;
    @include theme() {
      color: t($title);
    }
    @include respond('ipad') {
      font-size: 16px;
    }
  }
  &-imgs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
  &-img1, &-img2, &-img3, &-img4 {
    object-fit: cover;
    @include respond('large') {
      width: 300px;
    }
    width: 300px;
    @include respond('ipad-pro') {
      width: 40vw !important;
    }
    @include respond('ipad') {
      width: 36vw !important;
    }
    @include respond('phone') {
      width: 38vw !important;
    }
    
  }
  &-img1 {
    align-self: flex-end;    
    height: 100%;
    grid-area: 1 / 1 / 3 / 3;
  }
  &-img2 {
    height: 365px;
    grid-area: 1 / 3 / 2 / 5;
  }
  &-img3 {
    align-self: flex-end;    
    height: 100%;
    grid-area: 3 / 1 / 5 / 3;
  }
  &-img4 {
    align-self: flex-end;    
    height: 450px;
    grid-area: 2 / 3 / 5 / 5;
    padding-bottom: 20px;
  }
}

//overriding transition all from base
.pswp__container, .pswp__item {
  transition: none;
}

.pswp__img {
  object-fit: contain;
  cursor: auto !important;
}