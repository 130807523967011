@import '../../styles/index.scss';

.contact {
  .header {
    @include respond('ipad') {
      display: none;
    }
  }
  &.section {
    padding: 50px;
    @include respond('ipad-pro') {
      padding: 50px;
    }
    @include respond('ipad') {
      padding: 30px;
    }
    @include respond('phone') {
      padding: 0;
    }
  }
  &-container {
    padding: 20px 10px;
    display: flex;
    justify-content: space-evenly;
    height: 90%;
    @include respond('ipad') {
      flex-direction: column;
    }
  }
  &-info {
      width: 90%;
      margin-right: 50px;
      align-self: center;
      color: #fff;
      @include respond('ipad') {
        width: 100%;
        padding: 30px;
        margin-right: 0px;
      }
      @include respond('phone') {
        width: 100%;
        padding: 10px;
        margin-right: 0px;
      }
      &--hero {
        font-weight: 900;
        font-size: 50px;
        line-height: 60px;
        font-family: $cairo;
        margin: 0;
        margin-bottom: 5%;
        @include respond('ipad-pro') {
          font-size: 45px;
          line-height: 50px;
          margin-top: 5%;
          align-self: center;
        }
        @include respond('phone') {
          font-size: 32px;
          line-height: 32px;
        }
      }
      &--email, &--location {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        .svg {
          margin-right: 2%;
        }
      }

      &--text {
        cursor: pointer;
        @include respond('phone') {
          font-size: 5vw;
        }
      }
  }
  &-form {
    &--container {
      align-self: center;
    }
    background: $light-black;
    padding: 30px 50px;
    @include respond('ipad-pro') {
      padding: 10px 30px;
    }
    @include respond('ipad') {
      margin-top: 30px;
      padding: 50px 30px;
    }
    @include respond('phone') {
      padding: 20px 20px;
    }
    &--title.title {
      line-height: 64px;
      padding-bottom: 10px;
    }
    &--header {
      width: 80%;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #fff;
      margin-top: 0;
      @include respond('ipad-pro') {
        width: 80%;
        font-size: 16px;
      }
      @include respond('ipad') {
        width: 90%;
        font-size: 16px;
      }
    }
    #topping {
      margin-right: 10px;
    }
}
}