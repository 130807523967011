// MEDIA QUERIES
@mixin respond($breakpoint) {
  @if $breakpoint == 'large' {
    @media (min-width: 1300px) {
      @content;
    }
  }
  @if $breakpoint == 'phone' {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == 'ipad' {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == 'ipad-pro' {
    @media (max-width: 1200px) {
      @content;
    }
  }
}

// MEDIA QUERIES

@mixin theme() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
