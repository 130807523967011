@import '../../styles/index.scss';

.home {
  margin-top: 0 !important;
  &-bg {
    &-img {
      width: 100%;
      height: 400px;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top center;
      background-size: cover;
      @include respond('ipad') {
        height: 52vh;
        display: none;    
      }
      @include respond('large') {
        display: none;    
      }
    }
    &-phone {
      display: none;    
      width: 100%;
      height: 400px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include respond('ipad') {
        display: block;    
      }
      @include respond('large') {
        display: block;    
      }
    }
  }
  &-text {
    &-container {
      padding-bottom: 53px;
      width: 50%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond('ipad-pro') {
        width: 100%;
        padding: 0 30px 53px;
      }
      @include respond('phone') {
        padding: 0 20px 53px;
      }
    }
    &-p {
      p {
        text-align: center;
        font-size: $fsbody;
        @include theme() {
          color: t($title);
        }
        @include respond('phone') {
          font-size: $fss;
        }
      }
      &--black{
        font-weight: 700;
        @include theme() {
          color: t($title);
        }
        @include respond('phone') {
          text-align: center;
        }
      }
      &--gray{
        color: $dark-gray !important;
        font-weight: 700;
      }
    }
  }
  &-title {
    margin: 0;
    text-align: center;
    padding: 50px 50px 0px;
    font-weight: 800;
    font-size: 90px;
    line-height: 90px;
    @include theme() {
      color: t($title);
    }
    @include respond('large') {
      font-size: 100px;
      line-height: 100px;
    }
    @include respond('ipad-pro') {
      font-size: 7vw;
      line-height: 7vw;
    }
    @include respond('phone') {
      font-size: 8vw;
      padding: 30px 0px 0;
    }
  }
  &-subtitle {
    text-align: center;
    font-size: $fsm;
    @include theme() {
      color: t($text);
    }
    @include respond('phone') {
      font-size: $fsbody;
    }
  }
}
