@import '../../styles/index.scss';

$transition: .3s all ease;

.player{
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    color: #fff;
    font-weight: 400;
    overflow: hidden;
    height: 100%;
    margin-top: 10vh;
    @include respond('ipad-pro') {
      position: relative; 
      height: 100%;
      width: 100%;
      right: 0px;
      margin-top: 30px;
      padding: 0px 0px 10px;
    }
    .current-song{
      display: flex;
      flex-direction: row;
      padding: 20px 0px;
      color: #ffff;
      width: 100%;
      font-weight: 700;
      @include respond('phone') {
        flex-direction: column;
        width: 100%;
      }
      audio{
        display: none;
      }
      .img-wrap{
        position: relative;
        width: 270px;
        height: 270px;
        overflow: hidden;
        margin-right: 20px;
        @include respond('ipad-pro') {
          width: 100%;
          height: 100%;
        }
        @include respond('phone') {
          margin-right: auto;
          margin: 0 auto;
        }
        img {
          width: auto;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          @include respond('ipad-pro') {
            width: 100%;
          }
        }
      }
      .current-info {
        align-self: center;
        width: 300px;
        @include respond('ipad-pro') {
          width: 100%;
        }
      }
      .current-title {
        display: flex;
      }
      .song-name{
        font-size: 22px;
      }
      .song-autor{
        color: #fff;
      }
      .time{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
      #timeline{
        position: relative;
        margin: 0 auto;
        height: 5px;
        background: $dark-gray;
        border-radius: 5px;
        cursor: pointer;
        #playhead{
          position: relative;
          z-index: 2;
          width: 0;
          height: 5px;
          border-radius: 5px;
          background: #fff;
        }
      }
      .controls{
        display: flex;
        justify-content: space-around;
        button{
          color: #fff;
          border-radius: 50%;
          margin: 15px;
          font-size: 16px;
          text-align: center;
          transition: 0.2s;
          cursor: pointer;
          border: none;
          background: 0;
          &:focus{
            outline: none;
          }
          &.play{
            svg {
              cursor: pointer;
              width: 50px;
              height: 50px;
            }
            path {
              cursor: pointer;
            }
          }
          &.prev-next{
            path {
              cursor: pointer;
            }
            svg {
              width: 35px;
            }
            &:hover{
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .play-list{
      display: flex;
      flex-direction: column;
      height: 42vh;
      width: 100%;
      overflow-y: scroll;
      border: 1px solid white;
      @include respond('phone') {
        margin: 0 auto;
        height: 100%;
      }
      .track{
        border-bottom: 1px solid white;
        display: flex;
        align-items: center;
        transition: 0.3s;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px;
        &:hover{
          background: $medium-gray;
          position: relative;
        }
        &.current-audio{
          background: $dark-gray;
        }
        &.play-now{
          background: $dark-gray;
          position: relative;
        .track-img{
          filter: opacity(70%);
        }
      }
    .track-img{
      width: 90px;
      height: 90px;
      object-fit: cover;
    }
        .track-discr{
          margin-left: 15px;
          display: flex;
          min-width: 190px;
          .track-info {
            margin: 0 20px;
          }
          .track-name{
            font-size: 17px;
            margin-top: 8px;
          }
          .track-author{
            margin-top: 8px;
            font-weight: 300;
          }
        }

        .track-duration{
          min-width: 40px;
          margin-left: 10px;
          margin-right: 10px;
          font-weight: 500;
          @include respond('phone') {
            display: none;
          }
        }
      }
    }
  }


.play-list::-webkit-scrollbar {
    width: 5px;
}

.play-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.play-list::-webkit-scrollbar-track {
  background: $dark-gray; 
}

