@import '../../styles/index.scss';

.work {
    display: flex;
    align-items: center;
    &.section {
      padding: 50px;
      @include respond('ipad-pro') {
        margin-top: 0px;
      }
      @include respond('ipad') {
        padding: 30px;
      }
      @include respond('phone') {
        padding: 30px 20px;
      }
    }
  &-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include respond('ipad-pro') {
      flex-direction: column-reverse;    
    }
  }
  &-info {
    top: 0;
    left: 0;
    width: 45%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 42px;
    @include respond('ipad-pro') {
      position: relative; 
      width: 100%;
      right: 0px;
      margin-top: 0px;
      padding: 50px 30px;
    }
    @include respond('ipad') {
      padding: 50px 30px 140px;
    }
  }
  
  &-title {
    transform: rotate(-90deg);
    font-family: $cairo;
    font-weight: 700;
    font-size: 96px;
    position: absolute;
    bottom: 20px;
    right: 0;
    color: $dark-gray;
    @include respond('ipad-pro') {
      font-size: 75px;  
      right: -50px;
      bottom: -30px;
    }
  }
  
}

.work--spotify {
    cursor: pointer;
    path {
      @include respond('phone') {
        fill: #1BD760;
      }
    } 
    &:hover {
      path {
        fill: #1BD760;
        cursor: pointer;
      } 
    }
  
}
