@import '../../styles/index.scss';

.campaign{
  padding: 20px !important;
  .title {
    @include respond('phone') {
      font-size: 54px;
      line-height: 90px;
    }
  }
}