// Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Cairo
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

// // SF 
// @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

$roboto: 'Roboto', Verdana, sans-serif;

$cairo: 'Cairo', Verdana, sans-serif;

$fshero: 180px;
$fsbig: 96px;
$fsxl: 64px;
$fsl: 48px;
$fsm: 24px;
$fsbody: 16px;
$fss: 14px;
$fsxs: 10px;
$fsxxs: 6px;
