@import '../../styles/index.scss';

.oferta{
  &--btn {
    align-self: center;
    z-index: 1;
    &-inner {
      background-color: #790673;
      color: white;
      border: none;
      font-size: large;
      &:hover {
        border: none;
      }
    }
  }
  &--hero {
    position: relative;
    &-container {
      margin: auto;
      width: 800px;
      display: flex;
      flex-direction: column;
      height: 93vh;
      @include respond('ipad-pro') {
        padding: 0 50px;
        width: 100%;
      }
      @include respond('phone') {
        padding: 0 20px;
        width: 100%;
      }
    }
    &-triangulo {
      z-index: 1;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 50vw 57px 50vw;
      border-color: transparent transparent #ffff transparent;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      @include respond('ipad-pro') {
        border-width: 0 50vw 57px 50vw;
      }
    }
    &-overlay {
      position: absolute;
      z-index: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(8px) brightness(90%);
    }
    &-subtitle {
      z-index: 1;
      color: white;
      margin: 20px 0 0;
    }
    &-title {
      z-index: 1;
      color: white;
      font-size: 60px;
      margin: 20px 0;
      @include respond('ipad') {
        font-size: 50px;
        margin: 30px 0;
        line-height: 50px;
      }
      @include respond('phone') {
        font-size: 40px;
        margin: 30px 0;
        line-height: 40px;
      }
    }
    &-video {
      z-index: 1;
      width: 50vw;
      height: 40vh;
      align-self: center;
      @include respond('large') {
        width: 1000px;
      }
      @include respond('ipad-pro') {
        width: 100%;
      }
    }
    &-scroll {
      margin-top: 50px;
      color: white;
      text-align: center;
      z-index: 1;
      @include respond('phone') {
        margin-top: 5%;
      }
    }
  }
  &--footer {
    background-color: black;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    p {
      color: #ffff;
      font-weight: 800;
    }
  }
  &--overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    background-color: black;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }
}

.calendly-inline-widget {
  background-color: #ffff;
  height: 800px !important;
  @include respond('ipad-pro') {
    height: 1200px !important;
  }
  @include respond('ipad') {
    height: 1100px !important;
  }
  @include respond('phone') {
    height: 1000px !important;
  }
}
