@import '../../styles/index.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: 30px 50px 0 50px;
  position: relative;
  z-index: 1;
  @include respond('phone') {
    padding: 10px 20px;
  }
  &-title {
    align-self: center;
    color: $dark-gray;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    @include respond('ipad') {
      font-size: 14px;
    }
    @include respond('phone') {
      font-size: 2vw;
    }
  }
  &-logo {
    align-self: center;
    @include respond('ipad') {
      position: fixed;
      top: 15px;
      right: 10px;
    }
  }
}
